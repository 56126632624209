import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import LavaLamp from "../components/lavalamp/LavaLamp"

const IndexPage = () => (
  <>
  <LavaLamp pos = "0"></LavaLamp>
  <Layout  >
    <SEO title="Home" />
   
   
    <h2>Welcome to Just Math 24 site.</h2>
    <p></p>
    <div style={{ maxWidth: `1300px`, marginBottom: `1.45rem` }}>
    <Image />
    </div>
  </Layout></>
)



export default IndexPage
